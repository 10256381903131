<template lang="pug">
    div.main-container(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/insigne-bg-stroke-grey.png') + ')'} ")
        div.overlay
        div.main-content(id="content")
            div.layout-px-spacing
                div.page-header
                    div.page-title
                        h3  {{ $t('attendgroupInventory.requestinv')}}

                    div
                       nav.breadcrumb-one(aria-label="breadcrumb")
                          ol.breadcrumb
                                li.breadcrumb-item
                                    router-link(to="/pages/home") {{ $t('')}}
                                li.breadcrumb-item
                                    router-link(to="/pages/dashboardCenter") {{ $t('headers.dashboard')}}
                                li.breadcrumb-item.active(aria-current="page") {{ $t('attendgroupInventory.createcenter')}}

                div.row.mt-5.pb-5.d-flex.align-items-center
                    div.col-lg-6.col-md-6.layout-spacing.mx-auto
                        div.statbox.widget.box.box-shadow
                            div.widget-header
                                div.row
                                    div.col-xl-12.col-md-12.col-sm-12.col-12.text-center
                                vue-form(:state="formstate")
                                    div.widget-content.widget-content-area

                                        div.form-group.mb-4
                                            label(for="sParentCenter")  {{ $t('attendgroupInventory.attcenter')}}
                                            select.form-control(id="sParentCenter" v-model="model.idAttendCenter" @change="getInventoryRequest")
                                                option(value="0" selected) {{ $t('headers.select')}}
                                                option(:value=" Center.idCenter" v-for="Center in CenterList") {{ Center.sCenterName }}

                                        div.form-group.mb-4
                                            label(for="inventoryRequest")  {{ $t('attendgroupInventory.gropinvreq')}}
                                            select.form-control(id="inventoryRequest" v-model="model.idGroupRequestInventory")
                                                option(value="0" selected) {{ $t('headers.select')}}
                                                option(:value=" inventory.idGroupRequestInventory" v-for="inventory in InventoryRequests") {{ inventory.sGroupName + ' --> ' + inventory.requestNumber + ' unidades de inventario' }}

                                        div.form-group.mb-4(v-if="model.idGroupRequestInventory != 0")
                                            label() {{ $t('attendgroupInventory.requesteacher')}}
                                            input.form-control(type="text" :value="InventoryRequests.find(o => o.idGroupRequestInventory === model.idGroupRequestInventory).sTeacherName" readonly) 

                                        div.form-group.mb-4(v-if="model.idGroupRequestInventory != 0")
                                            label() {{ $t('attendgroupInventory.requestinv')}}
                                            input.form-control(type="text"  :value="InventoryRequests.find(o => o.idGroupRequestInventory === model.idGroupRequestInventory).requestNumber" readonly)                                             

                                        div.form-group.mb-4(v-if="model.idGroupRequestInventory != 0")
                                            label() {{ $t('attendgroupInventory.requestcomment')}}
                                            input.form-control(type="text"  :value="InventoryRequests.find(o => o.idGroupRequestInventory === model.idGroupRequestInventory).sRequestComments" readonly)                                             


                                        div.form-group.mb-4
                                            label(for="iNumber") {{ $t('attendgroupInventory.nummanainv')}}
                                            validate(tag="div")
                                                input.form-control(id="iNumber" name="iNumber" :min="1" required type="number" placeholder="Numero de unidades de inventario" v-model="model.AttendNumber" )
                                                field-messages.text-danger(name="iNumber" )
                                                    div(slot="required") {{ $t('attendgroupInventory.numcodreq')}}
                                                    div(slot="min") {{ $t('attendgroupInventory.numcero')}}

                                        div.form-group.mb-4
                                            label(for="sComments") {{ $t('attendgroupInventory.comments')}}
                                            validate(tag="div")
                                                input.form-control(type="text" id="sComments" required name="sComments" placeholder="sComments" v-model="model.sAttendComments")
                                                field-messages.text-danger(name="sComments" )
                                                    div(slot="required") {{ $t('attendgroupInventory.commentsugg')}}
                                        
                                        button.btn.btn-secondary(type="button" @click="Save") {{ $t('btns.btnsend')}}                                                    


</template>
<script>
/* eslint-disable */
export default {
    name: 'AttendGroupInventory',
    data() {
        return {
            formstate: {},
            CenterList: [],
            InventoryRequests: [],
            model: {
                idGroupRequestInventory: 0,
                sAttendComments: '',
                idPersonToAttend: 0,
                AttendNumber: 0,
                idAttendCenter: 0
            }
        }
    },
    methods: {
        async getCenterList() {
            await axios.get(apiURL + 'Center/getCenterListByAdmin?idPerson=' + localStorage.CertIdP, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                //Aqui falta quitar el centro administrador, el no puede solicitar inventario
                console.log(response.data.centerList)
                this.CenterList = response.data.centerList
            } )
            .catch( (error)  => {
                console.log("error")
            } )
        },
        async getInventoryRequest() {
            await axios.get(apiURL + 'Center/getInventoryGroupRequestList?idAttendCenter=' + this.model.idAttendCenter, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.InventoryRequests = response.data.requests
            } )
            .catch( (error)  => {
                console.log("error")
            } )            
        },
        async Save() {
            if(this.formstate.$invalid) {
                return;
            }
            this.model.idAttendPerson = localStorage.CertIdP

            this.openLoading(this.$t('msg.adminSwal1a'));

            this.model.idPersonToAttend = localStorage.CertIdP
            await axios.post(apiURL + 'Center/AttendGroupCodesRequest', this.model, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                Swal.close();
                if(response.data.bIsCorrect == false){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return
                }
                Swal.fire(this.$t('msg.adminSwal1'))
                router.push('/pages/home')
            } )
            .catch( (error) => {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )
        },
    },
    mounted() {
        //this.checkPermisions(this.$router.currentRoute.path)
        this.getCenterList()
        }    
        
}
</script>